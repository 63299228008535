import { useState } from 'react';
import styled from 'styled-components';

import {
  Box,
  Grid,
  Result,
  Spinner,
  SxObject,
  Typography,
} from '@hero-design/react';
import { UpChunk } from '@mux/upchunk';

import { useFetch } from '@packages/eh-utils/api';
import { DefaultError } from '@packages/eh-utils/api/types';
import { getFileServiceApiHost } from '@packages/eh-utils/browserEnv';
import { decamelizeKeys } from '@packages/eh-utils/formatObject';
import { mediaMaxQueries } from '@packages/hero-theme/utils';
import useWindowSize from '@shared/hooks/useWindowSize';

import useEditUserProfile from 'src/modules/User/hooks/useEditUserProfile';

import VideoRecording from '../VideoRecording';
import {
  PresignRequestParamsType,
  PresignResponseType,
  RecorderActionsCallback,
} from '../types';

const PageWrapper = styled(Box)<{ isMobileScreen?: boolean }>`
  width: 70%;

  ${mediaMaxQueries.xl} {
    width: 80%;
  }

  ${mediaMaxQueries.lg} {
    width: 100%;
  }

  background-color: ${({ isMobileScreen, theme }) =>
    !isMobileScreen ? theme.colors.defaultBackground : undefined};
`;

const IntroductionWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: rgba(255, 255, 255, 0.45);
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: rgba(0, 0, 0, 0.8);
  flex-direction: column;
  padding: ${({ theme }) => theme.space.large}px;
`;

type CandidateRecordingVideoProps = {
  onUploadSuccess?: () => void;
  sx?: SxObject;
} & RecorderActionsCallback;

const CandidateRecordingVideo = ({
  onUploadSuccess,
  sx,
  onStartRecording,
  onStopRecording,
  onUseVideo,
}: CandidateRecordingVideoProps) => {
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { isXSmallScreen: isMobileScreen } = useWindowSize();

  const { fetcher: updateUserProfile } = useEditUserProfile({
    onCompleted: () => {
      setIsUploading(false);
      onUploadSuccess?.();
    },
    onFailed: err => {
      setIsUploading(false);
      setErrorMessage(
        err?.error?.errors?.[0]?.message ||
          'Something went wrong, please try again.'
      );
    },
  });

  const startUpload = ({
    url,
    finalFile,
    videoId,
  }: {
    url: string;
    finalFile: File;
    videoId: string;
  }) => {
    try {
      const upChunk = UpChunk.createUpload({
        endpoint: url,
        maxFileSize: 2 ** 20, // 1GB
        file: finalFile,
      });

      upChunk.on('error', err => {
        setErrorMessage(err.detail);
        setIsUploading(false);
      });

      upChunk.on('progress', progressEvt => {
        setProgress(Math.floor(progressEvt.detail));
      });

      upChunk.on('success', () => {
        updateUserProfile({
          user_info: {
            video_introduction_id: videoId,
          },
        });
      });
    } catch (err) {
      setErrorMessage((err as Error).toString());
      setIsUploading(false);
    }
  };

  const { fetcher: fetchPresign } = useFetch<
    { data: PresignResponseType },
    PresignRequestParamsType
  >({
    method: 'POST',
    endpoint: `${getFileServiceApiHost()}/v1/presign`,
    formatBody: params => JSON.stringify(decamelizeKeys(params)),
  });

  if (errorMessage) {
    return <Result status="error" title="Error" subTitle={errorMessage} />;
  }

  return (
    <PageWrapper isMobileScreen={isMobileScreen} sx={sx}>
      <Grid>
        <Grid.Row>
          {!isMobileScreen && (
            <Grid.Col span={[24, 24, 24, 8, 8]}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '100%',
                  m: 'medium',
                }}
              >
                <Typography.Text sx={{ mb: 'small' }}>
                  1 minute video
                </Typography.Text>
                <Typography.Title level={4}>
                  Introduce yourself, your experience, and what you’re seeking
                  in a new role.
                </Typography.Title>
              </Box>
            </Grid.Col>
          )}
          <Grid.Col span={[24, 24, 24, 16, 16]} sx={{ position: 'relative' }}>
            <VideoRecording
              onStartRecording={onStartRecording}
              onStopRecording={onStopRecording}
              sx={{
                width: '100%',
                height: 'auto',
                aspectRatio: isMobileScreen ? '9 / 16' : '16 / 9',
              }}
              onUseVideo={async blob => {
                onUseVideo?.(blob);
                setIsUploading(true);
                try {
                  const res = await fetchPresign({
                    file_type: 'video',
                    provider: 'mux',
                  });

                  const createdFile = new File([blob], 'video-from-camera', {
                    type: blob.type,
                  });

                  startUpload({
                    url: res?.data.url,
                    finalFile: createdFile,
                    videoId: res?.data.id,
                  });
                } catch (err) {
                  const errMsg = (err as DefaultError)?.error?.errors?.[0]
                    ?.message;

                  setIsUploading(false);
                  setErrorMessage(
                    errMsg || 'Something went wrong, please try again.'
                  );
                }
              }}
            />
            {isMobileScreen && (
              <IntroductionWrapper>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    p: 'medium',
                  }}
                >
                  <Typography.Text sx={{ mb: 'small', textAlign: 'center' }}>
                    1 minute video
                  </Typography.Text>
                  <Typography.Title
                    level={5}
                    sx={{ mb: 'small', textAlign: 'center' }}
                  >
                    Introduce yourself, your experience, and what you’re seeking
                    in a new role.
                  </Typography.Title>
                </Box>
              </IntroductionWrapper>
            )}
            {isUploading && (
              <SpinnerWrapper>
                <Spinner
                  text={progress ? `${progress}% uploaded` : undefined}
                  data-test-id="uploading-video-spinner"
                />
                <Typography.Text
                  sx={{ textAlign: 'center', mt: 'small' }}
                  intent="primary"
                >
                  Hang tight - we are uploading your video now. Please stay on
                  this page!
                </Typography.Text>
              </SpinnerWrapper>
            )}
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </PageWrapper>
  );
};

export default CandidateRecordingVideo;
