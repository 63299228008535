import { ApiConfigs, useFetch } from '@packages/eh-utils/api/useFetch';
import { getDirectMainAppHost } from '@packages/eh-utils/browserEnv';
import { decamelizeKeys } from '@packages/eh-utils/formatObject';

import { TUserProfile } from '../types';

type UserProfileResponse = {
  data: TUserProfile;
};

const useEditUserProfile = ({
  onFailed,
  onCompleted,
}: Pick<
  ApiConfigs<UserProfileResponse, TUserProfile>,
  'onCompleted' | 'onFailed'
> = {}) => {
  const { fetcher, loading, error } = useFetch({
    endpoint: `${getDirectMainAppHost()}/api/v3/users/self_profile`,
    method: 'PATCH',
    formatBody: params => JSON.stringify(decamelizeKeys(params)),
    onCompleted,
    onFailed,
  });

  return {
    fetcher,
    loading,
    error,
  };
};

export default useEditUserProfile;
