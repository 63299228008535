import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';

import { Box, Button, Divider, Icon, Typography } from '@hero-design/react';

import Image from '@packages/eh-adapter/image';
import generateGetURL from '@packages/eh-utils/urlGenerator';
import { mediaQueries } from '@packages/hero-theme/utils';
import useWindowSize from '@shared/hooks/useWindowSize';
import { useMixpanelTracking } from '@shared/trackTools/mixpanel';

import { INTERESTED_CANDIDATE_FLOW_QUERY_VALUE } from '../constants';
import { TRACK_SMART_MATCH_VIDEO_INTRODUCTION } from '../tracks/events';

const screenResizeStyles = css`
  ${mediaQueries.sm} {
    max-width: 500px;
  }

  ${mediaQueries.md} {
    max-width: 700px;
  }

  ${mediaQueries.lg} {
    max-width: 800px;
  }
`;

const Container = styled.div`
  margin: 0px auto;
  padding: ${props => props.theme.space.large}px;
  ${screenResizeStyles}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const IntroductionVideoBenefits = ({
  text,
}: {
  text: string | React.ReactElement;
}) => (
  <Box
    tagName="span"
    sx={{ alignItems: 'center', display: 'flex', mt: 'small', mb: 'medium' }}
  >
    <Icon icon="checkmark" size="small" sx={{ mr: 'small' }} intent="primary" />
    <Typography.Text intent="subdued">{text}</Typography.Text>
  </Box>
);

const SmartMatchCandidateIntroStep = ({
  jobTitle,
  hostOrgName,
}: {
  jobTitle?: string;
  hostOrgName?: string;
}) => {
  const isSkipRecordVideo = useRef(true);
  const { track } = useMixpanelTracking();
  const router = useRouter();
  const { isSmallScreen } = useWindowSize();
  const handleCompleteAndRedirectToRecordVideo = () => {
    // eslint-disable-next-line immutable/no-mutation
    isSkipRecordVideo.current = false;
    track(TRACK_SMART_MATCH_VIDEO_INTRODUCTION.CLICK_RECORD_VIDEO_INTRODUCTION);
    router.push(
      generateGetURL('/smart_match_video_introduction', {
        from: INTERESTED_CANDIDATE_FLOW_QUERY_VALUE,
      })
    );
  };

  useEffect(
    () => () => {
      if (isSkipRecordVideo.current) {
        track(
          TRACK_SMART_MATCH_VIDEO_INTRODUCTION.SKIP_RECORD_VIDEO_INTRODUCTION
        );
      }
    },
    []
  );

  return (
    <Container>
      <Box>
        <Typography.Title level={3} sx={{ mb: 'medium' }}>
          Success!
        </Typography.Title>
        <Typography.Text>
          {jobTitle && hostOrgName
            ? `Your expression of interest in the ${jobTitle} role at ${hostOrgName} has
        been sent successfully.`
            : 'Your expression of interest in the this role at this organisation has been sent successfully.'}
        </Typography.Text>
        <Box
          tagName="span"
          sx={{ alignItems: 'center', display: 'flex', mt: 'large' }}
        >
          <Icon icon="video-1" size="xlarge" sx={{ mr: 'small' }} />
          <Typography.Title level={5}>
            Stand out from the crowd!
          </Typography.Title>
        </Box>
        <Typography.Text intent="subdued" sx={{ mt: 'small', mb: 'medium' }}>
          Record a video introduction to increase your chances of being seen!
        </Typography.Text>
        <IntroductionVideoBenefits
          text={
            <>
              Candidates with a video introduction are <b>4X</b> more likely to
              hear from hiring managers!
            </>
          }
        />
        <IntroductionVideoBenefits text="It’s quick and easy—just 2 minutes, and we'll guide you through every step." />
      </Box>
      <Image
        src="/assets/images/what_is_swag.png"
        alt="explanation-of-swag-img"
        style={{
          maxWidth: isSmallScreen ? undefined : 360,
          margin: '0 auto',
          width: '100%',
        }}
      />

      <Box
        sx={{
          position: 'sticky',
          bottom: 0,
          bgColor: 'default-light-background',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <Divider marginY="medium" />
        <Button
          text="Record video introduction"
          sx={{
            width: '100%',
            maxWidth: !isSmallScreen ? 400 : undefined,
          }}
          onClick={handleCompleteAndRedirectToRecordVideo}
        />
      </Box>
    </Container>
  );
};

export default SmartMatchCandidateIntroStep;
