import { forwardRef, useImperativeHandle } from 'react';
import { VideoActionsProps } from 'react-video-recorder';
import styled from 'styled-components';

import { Box, Button, Typography } from '@hero-design/react';

import Image from '@packages/eh-adapter/image';

import { RecorderActionsHandles } from '../types';

import Countdown from './Countdown';
import Timer from './Timer';

const ActionsWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: ${({ theme }) => theme.space.small}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type RecorderActionsProps = VideoActionsProps & {
  onExtraStartRecording?: () => void;
  onExtraStopRecording?: () => void;
};

const RecorderActions = (
  {
    isVideoInputSupported,
    isInlineRecordingSupported,
    thereWasAnError,
    isRecording,
    isCameraOn,
    streamIsReady,
    isConnecting,
    isRunningCountdown,
    countdownTime,
    timeLimit,
    onStartRecording,
    onStopRecording,
    onStopReplaying,
    onTurnOnCamera,
    onExtraStartRecording,
    onExtraStopRecording,
  }: RecorderActionsProps,
  ref: React.Ref<RecorderActionsHandles>
) => {
  useImperativeHandle(ref, () => ({
    onRetakeVideo: () => {
      onStopReplaying?.();
    },
    onRequestDevices: () => {
      onTurnOnCamera?.();
    },
  }));

  const renderContent = () => {
    if (
      (!isInlineRecordingSupported && !isVideoInputSupported) ||
      thereWasAnError ||
      isConnecting ||
      isRunningCountdown
    ) {
      return null;
    }

    if (isRecording) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Timer timeLimit={timeLimit} />
          <Button.Icon
            icon={
              <Image
                src="/assets/images/stop-circle-icon.svg"
                sx={{ width: 60, height: 60 }}
              />
            }
            onClick={() => {
              onStopRecording?.();
              onExtraStopRecording?.();
            }}
            data-test-id="stop-recording-button"
          />
        </Box>
      );
    }

    if (isCameraOn && streamIsReady) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography.Text intent="white" fontSize={18} fontWeight="semi-bold">
            Press record to start
          </Typography.Text>
          <Button.Icon
            icon={
              <Image
                src="/assets/images/start-circle-icon.svg"
                sx={{ width: 60, height: 60 }}
              />
            }
            onClick={() => {
              onStartRecording?.();
              onExtraStartRecording?.();
            }}
            data-test-id="start-recording-button"
          />
        </Box>
      );
    }

    return null;
  };

  return (
    <>
      {isRunningCountdown && <Countdown countdownTime={countdownTime} />}
      <ActionsWrapper>{renderContent()}</ActionsWrapper>
    </>
  );
};

export default forwardRef<RecorderActionsHandles, RecorderActionsProps>(
  RecorderActions
);
