export const TRACK_SMART_MATCH_VIDEO_INTRODUCTION = {
  CLICK_RECORD_VIDEO_INTRODUCTION:
    'SmartMatch - Click Record video introduction',
  SKIP_RECORD_VIDEO_INTRODUCTION: 'SmartMatch - Skip record video introduction',
  CLICK_READY_TO_RECORD: 'SmartMatch - Click I’m ready',
  CLICK_READY_TO_RECORD_VIA_DIRECT_URL:
    'SmartMatch - Click I’m ready via Direct URL',
  CLICK_VIEW_PROFILE: 'SmartMatch - Click View profile',
  CLICK_USE_THIS_VIDEO_VIA_INTERESTED_FLOW:
    'SmartMatch - Click Use this video via Interested candidate workflow',
  CLICK_USE_THIS_VIDEO_VIA_DIRECT_URL:
    'SmartMatch - Click Use this video via Direct URL',
  CLICK_USE_THIS_VIDEO_VIA_SWAG_PROFILE:
    'SmartMatch - Click Use this video via Swag profile',
  CLICK_USE_THIS_VIDEO_VIA_SWAG_JOB_SEARCH_PAGE:
    'SmartMatch - Click Use this video via the Swag Job Search page',
  CLICK_USE_THIS_VIDEO_VIA_SWAG_JOB_APPLICATION_PAGE:
    'SmartMatch - Click Use this video via the Swag Applications page',
  CLICK_USE_THIS_VIDEO_VIA_SWAG_APPLICATION_FLOW:
    'SmartMatch - Click Use this video via the Swag Applications flow',
  CLICK_VIEW_VIDEO_INTRODUCTION_EXPERIMENT_1:
    'SmartMatch - Click View video introduction experiment 1',
};
