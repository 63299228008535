import { useState } from 'react';
import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';

import {
  Alert,
  Box,
  Button,
  Divider,
  Icon,
  theme,
  Typography,
} from '@hero-design/react';

import { mediaQueries } from '@packages/hero-theme/utils';
import useWindowSize from '@shared/hooks/useWindowSize';
import { useMixpanelTracking } from '@shared/trackTools/mixpanel';

import CandidateRecordingVideo from './CandidateRecordingVideo';
import UploadVideoSuccessScreen from './UploadVideoSuccessScreen';

import { INTERESTED_CANDIDATE_FLOW_QUERY_VALUE } from '../constants';
import { TRACK_SMART_MATCH_VIDEO_INTRODUCTION } from '../tracks/events';

export const screenResizeStyles = css`
  ${mediaQueries.sm} {
    max-width: 500px;
  }
  ${mediaQueries.md} {
    max-width: 700px;
  }
  ${mediaQueries.lg} {
    max-width: 800px;
  }
`;

export const buttonStyles = css`
  ${mediaQueries.sm} {
    width: 100%;
  }
  ${mediaQueries.md} {
    width: 50%;
  }
  ${mediaQueries.lg} {
    max-width: 300px;
  }
`;

const Container = styled.div`
  margin: 0px auto;
  padding: ${props => props.theme.space.large}px;
  ${screenResizeStyles}
`;

const listNotes = [
  'You’re in a quiet, well-lit space.',
  'Your camera and mic are working.',
  'You speak clearly and confidently about yourself.',
];

const ListNotes = ({ text }: { text: string | React.ReactElement }) => (
  <Box
    tagName="span"
    sx={{ alignItems: 'center', display: 'flex', mt: 'small', mb: 'medium' }}
  >
    <Icon
      icon="checkmark"
      size="small"
      sx={{ mr: 'small', color: theme.colors.warning }}
    />
    <Typography.Text intent="subdued">{text}</Typography.Text>
  </Box>
);

const IntructionalStep = ({ onReady }: { onReady: () => void }) => {
  const { isSmallScreen } = useWindowSize();

  return (
    <Container>
      <Typography.Title level={2}>
        Help hiring managers get to know you
      </Typography.Title>
      <Typography.Text intent="subdued" sx={{ mt: 'medium' }}>
        Introduce yourself to future employers and improve your chances of being
        shortlisted by 30%!
      </Typography.Text>
      <Typography.Title sx={{ mt: 'medium', mb: 'medium' }} level={5}>
        Make sure that:
      </Typography.Title>
      {listNotes.map((note, index) => (
        <ListNotes key={index} text={note} />
      ))}
      <Alert
        icon={null}
        sx={{ mt: 'large', mb: 'large' }}
        content="Don’t worry, you’ll be able to review your video afterwards and re-record if needed."
      />
      <Typography.Text
        intent="subdued"
        sx={{
          fontStyle: 'italic',
          mt: 'medium',
          mb: 'medium',
          fontSize: 'small',
        }}
      >
        By submitting your video, you acknowledge that it can be viewed by
        hiring managers on our platform. You can update, or delete this video at
        any time.
      </Typography.Text>
      <Box
        sx={{
          position: 'sticky',
          bottom: 0,
          bgColor: 'default-light-background',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <Divider marginY="medium" />
        <Button
          text="I'm ready"
          onClick={onReady}
          sx={{
            width: '100%',
            maxWidth: !isSmallScreen ? 400 : undefined,
          }}
        />
      </Box>
    </Container>
  );
};

const IntroductionWrapper = () => {
  const [isReady, setIsReady] = useState(false);
  const [showSuccessfulPage, setShowSuccessfulPage] = useState(false);
  const { isXSmallScreen: isMobileScreen } = useWindowSize();
  const { track } = useMixpanelTracking();
  const { query } = useRouter();
  const isFromInterestedCandidateFlow =
    query?.from === INTERESTED_CANDIDATE_FLOW_QUERY_VALUE;

  if (showSuccessfulPage) {
    return (
      <Container>
        <UploadVideoSuccessScreen />
      </Container>
    );
  }

  if (isReady) {
    return (
      <>
        {!isMobileScreen && (
          <Container>
            <Typography.Title level={3} sx={{ mb: 'small' }}>
              Help Employers Get to Know you
            </Typography.Title>
            <Typography.Text intent="subdued">
              Introduce yourself to future employers and improve your chances of
              being shortlisted by <b>30%!</b>
            </Typography.Text>
          </Container>
        )}
        <CandidateRecordingVideo
          onUploadSuccess={() => setShowSuccessfulPage(true)}
          onUseVideo={() => {
            if (isFromInterestedCandidateFlow) {
              track(
                TRACK_SMART_MATCH_VIDEO_INTRODUCTION.CLICK_USE_THIS_VIDEO_VIA_INTERESTED_FLOW
              );
            } else {
              track(
                TRACK_SMART_MATCH_VIDEO_INTRODUCTION.CLICK_USE_THIS_VIDEO_VIA_DIRECT_URL
              );
            }
          }}
        />
      </>
    );
  }

  return (
    <IntructionalStep
      onReady={() => {
        if (isFromInterestedCandidateFlow) {
          track(TRACK_SMART_MATCH_VIDEO_INTRODUCTION.CLICK_READY_TO_RECORD);
        } else {
          track(
            TRACK_SMART_MATCH_VIDEO_INTRODUCTION.CLICK_READY_TO_RECORD_VIA_DIRECT_URL
          );
        }

        setIsReady(true);
      }}
    />
  );
};

export default IntroductionWrapper;
