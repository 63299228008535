import styled from 'styled-components';

import { Box } from '@hero-design/react';

const VideoViewWrapper = styled(Box)`
  border: ${({ theme }) => theme.borderWidths.base}px solid
    ${({ theme }) => theme.colors.defaultBorder};
  background-color: ${({ theme }) => theme.colors.defaultLightBackground};
  width: 100%;
  height: 100%;
  flex: 1;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default VideoViewWrapper;
