import { useRef, useState } from 'react';
import ReactVideoRecorder from 'react-video-recorder';

import { Box, Button, Spinner, SxObject, Typography } from '@hero-design/react';

import { RecorderActionsCallback } from '../types';

import RecorderActions from './components/RecorderActions';
import VideoViewWrapper from './components/VideoViewWrapper';
import { RecorderActionsHandles } from './types';

type VideoRecordingProps = {
  sx?: SxObject;
} & RecorderActionsCallback;

const VideoRecording = ({
  sx,
  onUseVideo,
  onStartRecording,
  onStopRecording,
}: VideoRecordingProps) => {
  const recordActionsRef = useRef<RecorderActionsHandles>(null);
  const [blob, setBlob] = useState<Blob | null>(null);

  return (
    <Box sx={sx}>
      <ReactVideoRecorder
        isOnInitially
        isFlipped
        showReplayControls
        replayVideoAutoplayAndLoopOff
        countdownTime={3000}
        timeLimit={60000}
        renderErrorView={() => (
          <VideoViewWrapper sx={{ flexDirection: 'column', padding: 'large' }}>
            <Typography.Title level={3} sx={{ mb: 'medium' }}>
              Cam & Mic are not active
            </Typography.Title>
            <Typography.Text sx={{ textAlign: 'center' }}>
              To record video, your browser will need to request access to your
              camera & microphone.
            </Typography.Text>
            <Button
              variant="filled"
              intent="primary"
              text="Request permissions"
              onClick={() => {
                recordActionsRef.current?.onRequestDevices();
              }}
              sx={{ mt: 'medium' }}
            />
          </VideoViewWrapper>
        )}
        renderLoadingView={() => (
          <VideoViewWrapper>
            <Spinner text="Requesting device access..." />
          </VideoViewWrapper>
        )}
        renderActions={props => (
          <RecorderActions
            ref={recordActionsRef}
            onExtraStartRecording={onStartRecording}
            onExtraStopRecording={onStopRecording}
            {...props}
          />
        )}
        onRecordingComplete={videoBlob => {
          setBlob(videoBlob);
        }}
      />
      {blob && (
        <Box sx={{ mt: 'large', display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              maxWidth: 300,
            }}
          >
            <Button
              text="Use this video"
              onClick={() => {
                onUseVideo?.(blob);
                setBlob(null);
              }}
            />
            <Button
              variant="text"
              text="Re-take video"
              onClick={() => {
                recordActionsRef.current?.onRetakeVideo();
                setBlob(null);
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default VideoRecording;
