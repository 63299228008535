import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import styled from 'styled-components';

import { Alert, Box, Button, Typography } from '@hero-design/react';

import generateGetURL from '@packages/eh-utils/urlGenerator';
import useWindowSize from '@shared/hooks/useWindowSize';
import { useMixpanelTracking } from '@shared/trackTools/mixpanel';

import { TRACK_SMART_MATCH_VIDEO_INTRODUCTION } from '../tracks/events';

const StyledImage = styled.img`
  height: auto;
  width: 100%;
  max-width: 450px;
`;

const UploadVideoSuccessScreen = () => {
  const { track } = useMixpanelTracking();
  const { isXSmallScreen: isMobileScreen } = useWindowSize();
  const router = useRouter();
  const { data: session } = useSession();
  const { user } = session || {};
  const normalizeHref = useCallback(
    (href: string) =>
      generateGetURL(href, {
        mode: router?.query?.mode,
      }),
    [router?.query?.mode]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box>
        <Typography.Title level={3} sx={{ mb: 'medium' }}>
          Congratulations!
        </Typography.Title>
        <Typography.Text sx={{ mb: 'medium' }}>
          Your video has been successfully uploaded.
        </Typography.Text>
        <Alert content="You can view, delete, or re-record your video anytime in your Swag Jobs profile." />
      </Box>
      <StyledImage
        data-test-id="upload-video-success-image"
        src="/assets/images/smartmatch-video-introduction.png"
        alt="SmartMatch video introduction"
      />
      <Button
        text="View profile"
        onClick={() => {
          track(TRACK_SMART_MATCH_VIDEO_INTRODUCTION.CLICK_VIEW_PROFILE);
          if (user) {
            router.push(normalizeHref(`/user/${user.friendlyId}`));
          }
        }}
        sx={{
          width: '100%',
          maxWidth: isMobileScreen ? undefined : 300,
          mt: 'large',
        }}
      />
    </Box>
  );
};

export default UploadVideoSuccessScreen;
