import { useEffect, useState } from 'react';

import { Typography } from '@hero-design/react';

const CountdownTimer = ({ timeLimit }: { timeLimit: number }) => {
  const pad = (unit: number) => unit.toString().padStart(2, '0');
  const getHumanTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${pad(minutes)}:${pad(remainingSeconds)}`;
  };

  const [seconds, setSeconds] = useState(timeLimit ? timeLimit / 1000 : 0);
  const [humanTime, setHumanTime] = useState(getHumanTime(seconds));

  useEffect(() => {
    const timer = setInterval(() => {
      const nextSeconds = timeLimit ? seconds - 1 : seconds + 1;

      setSeconds(nextSeconds);
      setHumanTime(getHumanTime(nextSeconds));
    }, 1000);

    return () => clearInterval(timer);
  }, [seconds, timeLimit]);

  return (
    <Typography.Text intent="white" fontWeight="bold">
      {humanTime}
    </Typography.Text>
  );
};

export default CountdownTimer;
