import { useEffect, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: rgba(0, 0, 0, 0.45);
`;

const CountdownText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes['10xlarge']}px;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.lightText};
`;

const Countdown = ({ countdownTime }: { countdownTime: number }) => {
  const [number, setNumber] = useState(countdownTime / 1000);

  useEffect(() => {
    if (number > 0) {
      const timeout = setTimeout(() => {
        setNumber(number - 1);
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [number]);

  return (
    <Wrapper>
      {number && number > 0 && (
        <CountdownText>{number.toString()}</CountdownText>
      )}
    </Wrapper>
  );
};

export default Countdown;
